//
// map.js
// Theme module
//

const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoiMjYybWVkaWEiLCJhIjoiY2xwMWQ5amxwMGx4ejJpbmxkeThkZTBwdSJ9.w0kzBHqYmVIxyUdkyN5vxA';

maps.forEach((map) => {
  const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

  const defaultOptions = {
    container: map,
    style: 'mapbox://styles/262media/clp1dbi5v008001qthm8q3l1t', // Use Mapbox Streets style for a blue-themed map
    center: [28.419439, 16.542282], // Your desired coordinates
    interactive: false, // Disable user interactions (dragging and zooming)
  };
  
 
  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  // Get access token
  mapboxgl.accessToken = accessToken;

  // Init map
  new mapboxgl.Map(options);

});
